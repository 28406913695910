/* AdviceModal.css */
.acknowledge-animating {
    animation: pop-button 0.4s ease-in-out forwards;
    opacity: 0.6;
  }
  
  @keyframes pop-button {
    0% {
      transform: scale(1);
      background-color: #007bff;
    }
    50% {
      transform: scale(1.1);
      background-color: #0d6efd;
    }
    100% {
      transform: scale(1);
      background-color: #198754;
    }
  }
  